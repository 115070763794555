import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Chip } from "@mui/material";

import { TagValues } from "./tags";

interface VehicleTagProps {
  tag: TagValues;
  showCount?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: (tag: TagValues) => void;
  onDelete?: (tag: TagValues) => void;
}

export default function VehicleTag({
  tag,
  showCount = false,
  isActive = false,
  isDisabled = false,
  onClick,
  onDelete,
}: VehicleTagProps) {
  return (
    <Chip
      icon={<LocalOfferIcon />}
      variant={isActive ? "filled" : "outlined"}
      label={tag.key + (showCount ? ` (${tag.count})` : "")}
      onClick={onClick ? () => !isDisabled && onClick?.(tag) : undefined}
      onDelete={onDelete ? () => !isDisabled && onDelete?.(tag) : undefined}
      size="small"
    />
  );
}
