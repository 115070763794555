import { Box, Typography } from "@mui/material";

import { Tag } from "../../../../../backend/src/tags/tag.entity";
import { useTags } from "../../providers/TagsProvider";
import VehicleTag from "../VehicleTag";

interface TagsProps {
  selectedFleetTags: any;
  setSelectedFleetTags: any;
  showAdd?: boolean;
  showCount?: boolean;
  isDisabled?: boolean;
}
export type TagValues = {
  id: Tag["id"];
  key: Tag["key"];
  count?: number | null;
};

const Tags = ({
  selectedFleetTags,
  setSelectedFleetTags,
  showAdd = true,
  showCount = true,
  isDisabled = false,
}: TagsProps) => {
  const { fleetTagsList } = useTags();

  const handleVehicleTagClick = async (tag: TagValues) => {
    let selectedTags;

    if (selectedFleetTags.includes(tag.id)) {
      selectedTags = selectedFleetTags.filter((t: number) => t !== tag.id);
    } else {
      selectedTags = [...selectedFleetTags, tag.id];
    }
    setSelectedFleetTags(selectedTags);
  };

  if (!showAdd && fleetTagsList?.length === 0) return <></>;

  return (
    <Box
      py={1}
      display={"flex"}
      flexWrap="wrap"
      justifyContent={"flex-start"}
      rowGap={1}
      gap={1}
    >
      <Box alignSelf={"center"}>
        <Typography variant="subtitle2">Tags</Typography>
      </Box>
      {fleetTagsList?.map((tag) => {
        if (!tag?.id) return null;
        const isActive = selectedFleetTags.includes(tag.id);
        return (
          <VehicleTag
            key={tag.id}
            tag={tag}
            isActive={isActive}
            showCount={showCount}
            onClick={handleVehicleTagClick}
            isDisabled={isDisabled}
          />
        );
      })}
    </Box>
  );
};

export default Tags;
