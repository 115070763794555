import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { GridFilterItem } from "@mui/x-data-grid-pro";

import { Option } from "shared/types";

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option<number>,
  state: AutocompleteRenderOptionState
) => (
  <Typography {...props}>
    <Checkbox
      icon={<CheckBoxOutlineBlank />}
      checkedIcon={<CheckBox />}
      style={{ marginRight: 8 }}
      checked={state.selected}
    />
    {option.label}
  </Typography>
);

export const DataGridTagAutocomplete = ({
  item,
  applyValue,
  options,
}: {
  item: GridFilterItem;
  applyValue: (value: GridFilterItem) => void;
  options: Option<number>[];
}) => {
  const selectedRequestStatusOptions = options?.filter((option) =>
    item.value?.find((tagId: number) => tagId === option.value)
  );

  const firstTwoTags = selectedRequestStatusOptions
    .slice(0, 2)
    .map((option) => option.label)
    .join(", ");

  const remainingTagCount = selectedRequestStatusOptions.length - 2;

  return (
    <FormControl fullWidth variant="standard">
      <Autocomplete
        multiple
        sx={{
          "& .MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap",
          },
        }}
        options={options}
        value={selectedRequestStatusOptions ?? []}
        onChange={(_event, value) => {
          applyValue({ ...item, value: value.map((v) => v.value) });
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={renderOption}
        renderTags={() => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {firstTwoTags}
            </Typography>
            <Typography
              sx={{
                marginLeft: "4px",
              }}
            >
              {remainingTagCount > 0 ? `+${remainingTagCount}` : ""}
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Request Tags"}
            variant="standard"
            sx={{
              whiteSpace: "nowrap",
            }}
          />
        )}
      />
    </FormControl>
  );
};
