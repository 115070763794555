import { useEffect } from "react";

import { GridFilterItem } from "@mui/x-data-grid-pro";

import { DataGridTagAutocomplete } from "shared/components/DataGridTable/TagAutocomplete";
import { useGetFleetVehiclesTagsLazyQuery } from "shared/generated/graphql";
import { Option } from "shared/types";

import { useFleets } from "../providers/FleetProvider";

export const VehicleTagAutoComplete = ({
  item,
  applyValue,
}: {
  item: GridFilterItem;
  applyValue: (value: GridFilterItem) => void;
}) => {
  const { currentFleetIds } = useFleets();
  const [getTags, { data }] = useGetFleetVehiclesTagsLazyQuery();

  useEffect(() => {
    async function fetchTags() {
      await getTags({
        variables: {
          fleetIds: currentFleetIds,
        },
      });
    }
    fetchTags();
  }, [currentFleetIds, getTags]);

  const options: Option<number>[] =
    data?.tagsFleetVehicles?.flatMap((tag) => {
      if (!tag.id || !tag.key || typeof tag.key !== "string") {
        return [];
      }
      return {
        label: tag.key,
        value: tag.id,
      };
    }) ?? [];

  return (
    <DataGridTagAutocomplete
      item={item}
      applyValue={applyValue}
      options={options}
    />
  );
};
